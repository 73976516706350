/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// @mui material components
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';

// @mui icons
// import CloseIcon from '@mui/icons-material/Close';
// Material Kit 2 React components
import MKBox from 'components/box';
import MKButton from 'components/button';
import MKTypography from 'components/typography';
import PropTypes from 'prop-types';

function DataProtectionModal({ onAgree }) {
  const [showDataProtectionModal, setShowDataProtectionModal] = useState(true);

  // -------------------------
  const [isDataProtectionAgreed, setIsDataProtectionAgreed] = useState(0);

  const handleDataProtectionAgree = () => {
    // 1 -- user does not accept
    setIsDataProtectionAgreed(1);
    setShowDataProtectionModal(false);
    console.log('user agree');
    onAgree(true); // Notify the parent component that the user agreed
  };

  // Define propTypes for your component
  DataProtectionModal.propTypes = {
    onAgree: PropTypes.func.isRequired,
  };

  const handleDataProtectionNotAgreed = () => {
    // 2 -- user does not accept
    setIsDataProtectionAgreed(2);
    console.log('user disagrees');
    setShowDataProtectionModal(false);
    onAgree(false); // Notify the parent component that the user did not agree
  };

  const handleDataProtectionCancel = () => {
    // 3 -- user cancels
    setIsDataProtectionAgreed(3);
    console.log('user cancel');
  };

  return (
    <MKBox component="section">
      <Container>
        <Modal open={showDataProtectionModal} sx={{ display: 'grid', placeItems: 'center' }}>
          <Slide direction="down" in={showDataProtectionModal} timeout={500}>
            <MKBox
              position="relative"
              width="800px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
              sx={{ overflowY: 'auto', maxHeight: '600px' }} // for scrollability
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Data Protection Policy</MKTypography>
                {/*<CloseIcon fontSize="medium" sx={{ cursor: 'pointer' }} onClick={toggleModal} />*/}
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKBox p={2}>
                  {isDataProtectionAgreed === 0 ? (
                    <>
                      <MKBox p={2}>
                        <h4>Introduction</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          [Your Company Name] is committed to protecting the privacy and security of
                          personal information in accordance with applicable data protection laws,
                          including the Jamaican Data Protection Act [Insert any other relevant
                          international regulations, such as GDPR]. This Data Protection Policy
                          outlines our commitment to the responsible handling, processing, and
                          protection of personal data collected by [Your Company Name] in the course
                          of its business operations.
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>Scope</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          This policy applies to all employees, contractors, and third-party service
                          providers who have access to personal data collected and processed by
                          [Your Company Name].
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>Principles of Data Protection</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          Y[Your Company Name] adheres to the following principles in its data
                          protection practices:
                        </MKTypography>
                        <ul>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              <b>Lawfulness, Fairness, and Transparency: </b>Personal data is
                              processed lawfully, fairly, and transparently, and individuals are
                              informed of the purposes for which their data is collected and
                              processed.
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              <b>Purpose Limitation: </b>Personal data is collected for specified,
                              explicit, and legitimate Purpose Limitation: Personal data is
                              collected for specified, explicit, and legitimate purposes and is not
                              further processed in a manner incompatible with those
                              purposes.purposes and is not further processed in a manner
                              incompatible with those purposes.
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              <b>Data Minimization: </b>[Your Company Name] ensures that personal
                              data processed is adequate, relevant, and limited to what is necessary
                              for the purposes for which it is processed.
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              <b>Accuracy:</b> [Your Company Name] takes reasonable steps to ensure
                              that personal data is accurate, up-to-date, and corrected when
                              necessary.
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              <b>Storage Limitation:</b> Personal data is kept in a form that
                              permits identification for no longer than is necessary for the
                              purposes for which it is processed.
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              <b>Integrity and Confidentiality:</b> [Your Company Name] ensures the
                              security, integrity, and confidentiality of personal data through
                              appropriate technical and organizational measures.
                            </MKTypography>
                          </li>
                        </ul>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>4. Data Collection and Processing</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          [Your Company Name] collects and processes personal data for the following
                          purposes:
                        </MKTypography>
                        <ul>
                          <li>
                            [Specify the purposes for which personal data is collected and
                            processed, e.g., providing services, customer support, marketing, etc.]
                          </li>
                        </ul>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>5. Data Subject Rights</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          Individuals have the following rights regarding their personal data:
                        </MKTypography>
                        <ul>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              Right to access
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              Right to rectification
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              Right to erasure
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              Right to restrict processing
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              Right to data portability
                            </MKTypography>
                          </li>
                          <li>
                            <MKTypography variant="body2" color="secondary" fontWeight="regular">
                              Right to object
                            </MKTypography>
                          </li>
                        </ul>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          Requests to exercise these rights should be submitted to [Specify Contact
                          Information].
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>6. Data Security</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          [Your Company Name] implements appropriate technical and organizational
                          measures to ensure the security of personal data, including but not
                          limited to encryption, access controls, and regular security assessments.
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>7. Data Breach Response</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          In the event of a data breach, [Your Company Name] will promptly assess
                          the situation, take appropriate measures to mitigate the breach, and
                          comply with any legal obligations to notify affected individuals and
                          authorities.
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>8. International Data Transfers</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          This Policy constitutes the entire agreement between the parties with
                          respect to your use of the Tools.
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>Modifications</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          [Your Company Name] may transfer personal data internationally in
                          compliance with applicable data protection laws. Adequate safeguards, such
                          as standard contractual clauses or binding corporate rules, will be
                          implemented to protect the data.
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>9. Training and Awareness</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          [Your Company Name] provides training to employees and contractors on data
                          protection principles and practices to ensure awareness and compliance.
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>10. Data Protection Officer</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          [Your Company Name] has appointed a Data Protection Officer (DPO)
                          responsible for overseeing data protection efforts. The DPO can be
                          contacted at [Specify Contact Information].
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>11. Review and Update</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          This Data Protection Policy will be reviewed and updated regularly to
                          ensure its continued relevance and compliance with applicable laws and
                          regulations.
                        </MKTypography>
                      </MKBox>

                      <MKBox p={2}>
                        <h4>12. Contact Information</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          For inquiries or concerns related to this Data Protection Policy, please
                          contact: [Your Company Contact Information]
                        </MKTypography>
                      </MKBox>
                    </>
                  ) : (
                    isDataProtectionAgreed === 3 && (
                      <MKBox p={2}>
                        <h4>Notice</h4>
                        <MKTypography variant="body2" color="secondary" fontWeight="regular">
                          I understand that you disagree with our policy, but in order to have
                          access to the internal tools, you must agree to all of our terms and
                          conditions.
                        </MKTypography>
                        <h4>Are you sure you want to cancel?</h4>
                      </MKBox>
                    )
                  )}
                </MKBox>
              </MKBox>
              {/*Modal Footer*/}
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                {isDataProtectionAgreed === 0 ? (
                  <MKButton variant="gradient" color="success" onClick={handleDataProtectionAgree}>
                    Yes, I have read, understand, and agree.
                  </MKButton>
                ) : (
                  <MKButton variant="gradient" color="success" onClick={handleDataProtectionAgree}>
                    No, I want to accept the policy.
                  </MKButton>
                )}

                {isDataProtectionAgreed === 0 ? (
                  <MKButton variant="gradient" color="primary" onClick={handleDataProtectionCancel}>
                    I do not agree
                  </MKButton>
                ) : (
                  <MKButton
                    variant="gradient"
                    color="primary"
                    onClick={handleDataProtectionNotAgreed}
                  >
                    Yes, I am sure
                  </MKButton>
                )}
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
      </Container>
    </MKBox>
  );
}

DataProtectionModal.propTypes = {
  onAgree: PropTypes.func.isRequired,
};
export default DataProtectionModal;
