/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

//Image
import managed_services_and_staff_augmentation_2 from '../../../assets/images/managed-staff-and-augmentation/managed_services_and_staff_augmentation_2.jpeg';

//CSS
import './ManagedServicesAndStaffAugmentation.css';

function WhyUs() {
  return (
    <MKBox component="section" pt={10} pb={5} style={{ background: '#f0f2f5' }}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6} sx={{ ml: '', mt: { xs: 3, lg: 0 } }}>
            <div className="training-benefit">
              <span>
                <i className="fas fa-check-circle managed-services-SA-icon-small"></i> Why Choose
                Us?
              </span>
            </div>
            <div className="training-description">
              <p>
                With our comprehensive Managed Services offering, we bring years of industry
                expertise and a dedicated team to ensure seamless execution of outsourced functions.
                Our tailored solutions are designed to optimize efficiency, enhance productivity,
                and drive cost savings for your organization. By leveraging our specialized
                knowledge and resources, you can unlock new levels of operational excellence while
                maintaining the flexibility to adapt to changing business needs. Rest assured, we
                are committed to delivering exceptional results and exceeding your expectations as
                your trusted partner in managing critical business processes.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12} sx={{ ml: { lg: '100px' } }}>
                <MKBox>
                  <img src={managed_services_and_staff_augmentation_2} className="training-img" />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyUs;
