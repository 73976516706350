/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import PropTypes from "prop-types";

function Counters({ type }) {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={3} md={4}>
            <DefaultCounterCard
              count={5}
              type={type.mission}
              suffix="+"
              title="Mission"
              description={type.mission}
              color="info"
              alignment="left"
            />
          </Grid>
          <Grid item xs={2} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={15}
              suffix="+"
              type={type.vision}
              title="Vision"
              description={type.vision}
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={2} md={4}>
            <DefaultCounterCard
              count={4}
              type={type.values}
              title="Values"
              description={type.values}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Counters.propTypes = {
  type: PropTypes.shape({
    mission: PropTypes.string.isRequired,
    vision: PropTypes.string.isRequired,
    values: PropTypes.string.isRequired,
  }).isRequired,
};

export default Counters;
