/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCardTwo from 'examples/Cards/InfoCards/DefaultInfoCardTwo';

//Image
// import software_development_1 from '../../../assets/images/software-development/software_development_1.jpeg';
import software_development_2 from '../../../assets/images/software-development/software_development_2.png';
import software_development_3 from '../../../assets/images/software-development/software_development_3.png';
import software_development_4 from '../../../assets/images/software-development/software_development_4.png';
import software_development_5 from '../../../assets/images/software-development/software_development_5.png';
import software_development_6 from '../../../assets/images/software-development/software_development_6.jpg';
import software_development_7 from '../../../assets/images/software-development/software_development_7.png';
import software_development_8 from '../../../assets/images/software-development/software_development_8.png';
import software_development_9 from '../../../assets/images/software-development/software_development_9.png';
import software_development_18 from '../../../assets/images/software-development/software_development_18.jpeg';

// CSS
import './MobileApplication.css';

function MobileApplication() {
  return (
    <MKBox component="section" pt={12} pb={5}>
      <Container>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 3, lg: 0 } }}>
            <img
              src={software_development_18}
              className="mobile-app-image"
              alt="software development"
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <DefaultInfoCardTwo
                    title="Mobile Application Develoment"
                    description="We offers mobile application development services, which involve the process
                    of creating software applications specifically designed to run on mobile devices such as smartphones
                     and tablets. Overall, our mobile application development services encompass the entire lifecycle of
                     app development, from initial planning and design to development, testing, deployment, and ongoing
                     maintenance, ensuring that clients receive a well-designed, high-performing, and user-friendly
                     mobile application tailored to their specific requirements."
                  />
                  <div className="box-container-software-dev">
                    <div className="box-bg-software-dev">
                      <img src={software_development_3} alt="Android" />
                      <p className="box-text-software-dev">Android</p>
                    </div>
                    <div className="box-bg-software-dev">
                      <img src={software_development_2} alt="Apple" />
                      <p className="box-text-software-dev">Apple</p>
                    </div>
                    <div className="box-bg-software-dev">
                      <img src={software_development_6} alt="Flutter" />
                      <p className="box-text-software-dev">Flutter</p>
                    </div>
                    <div className="box-bg-software-dev">
                      <img src={software_development_9} alt="Ionic" />
                      <p className="box-text-software-dev">Ionic</p>
                    </div>
                  </div>

                  <div className="box-container-software-dev">
                    <div className="box-bg-software-dev">
                      <img src={software_development_7} alt="Java" />
                      <p className="box-text-software-dev">Java</p>
                    </div>
                    <div className="box-bg-software-dev">
                      <img src={software_development_5} alt="React Native" />
                      <p className="box-text-software-dev">React Native</p>
                    </div>
                    <div className="box-bg-software-dev">
                      <img src={software_development_8} alt="Swift" />
                      <p className="box-text-software-dev">Swift</p>
                    </div>
                    <div className="box-bg-software-dev">
                      <img src={software_development_4} alt="Windows" />
                      <p className="box-text-software-dev">Windows</p>
                    </div>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default MobileApplication;
