/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from '../../components/box';
import MKTypography from '../../components/typography';

// Material Kit 2 React examples
import DefaultNavbar from '../../examples/Navbars/DefaultNavbar';
import DefaultFooter from '../../examples/Footers/DefaultFooter';

// Training page
import WhyUs from './sections/WhyUs';
import Training from './sections/Training';

// Routes
import routes from '../../routes';
import footerRoutes from '../../footer.routes';

// Images
import training_header_image from '../../assets/images/training/training.gif';
import PropTypes from "prop-types";

function TrainingInfo({ type }) {
  const trainingData =
    type === "CX" ? {
      header: {
        headerTitle: "Customer Experience Training Services",
        title: "Empower Your Team to Deliver an Exceptional Customer Experience",
        description: "Transform your business through exceptional customer experiences. Our comprehensive training programs and expert document creation services empower your team to deliver outstanding service. We'll equip your employees with the skills to build lasting relationships, resolve issues effectively, and exceed customer expectations. Let our tailored training solutions and clear, concise documentation drive customer satisfaction and loyalty. Schedule a free consultation to learn how we can help you create a world-class customer experience."
      },
      footer: {
        title: "Performance Metrics",
        description: "Understanding the impact of training on your business is crucial, which is why we integrate performance metrics into our programs. We provide you with tools and strategies to measure the effectiveness of the training, from customer satisfaction surveys to service delivery analytics. These metrics allow you to track progress, identify areas for further improvement, and demonstrate the return on investment from the training. By analyzing the data, you can make informed decisions that drive continuous enhancement of your customer service operations."
      }
    } : type === "CS" ? {
      header: {
        headerTitle: "Customer Service Training Services",
        title: "Empower Your Team to Deliver an Exceptional Customer Service",
        description: "Transform your business through exceptional customer experiences. Our comprehensive training programs and expert document creation services empower your team to deliver outstanding service. We'll equip your employees with the skills to build lasting relationships, resolve issues effectively, and exceed customer expectations. Let our tailored training solutions and clear, concise documentation drive customer satisfaction and loyalty. Schedule a free consultation to learn how we can help you create a world-class customer experience."
      },
      footer: {
        title: "Performance Metrics",
        description: "Understanding the impact of training on your business is crucial, which is why we integrate performance metrics into our programs. We provide you with tools and strategies to measure the effectiveness of the training, from customer satisfaction surveys to service delivery analytics. These metrics allow you to track progress, identify areas for further improvement, and demonstrate the return on investment from the training. By analyzing the data, you can make informed decisions that drive continuous enhancement of your customer service operations."
      }
    } : {
      header: {
        header: "Training Documents Preparation Services",
        title: "Empower Your Team to Deliver Exceptional Training",
        description: "Transform your business through exceptional customer experiences. Our comprehensive training programs and expert document creation services empower your team to deliver outstanding service. We'll equip your employees with the skills to build lasting relationships, resolve issues effectively, and exceed customer expectations. Let our tailored training solutions and clear, concise documentation drive customer satisfaction and loyalty. Schedule a free consultation to learn how we can help you create a world-class customer experience."
      },
      footer: {
        title: "Performance Metrics",
        description: "Understanding the impact of training on your business is crucial, which is why we integrate performance metrics into our programs. We provide you with tools and strategies to measure the effectiveness of the training, from customer satisfaction surveys to service delivery analytics. These metrics allow you to track progress, identify areas for further improvement, and demonstrate the return on investment from the training. By analyzing the data, you can make informed decisions that drive continuous enhancement of your customer service operations."
      }
    };

  return (
    <>
      <DefaultNavbar
        routes={routes.map((r) => r.type === 'nav' && r)}
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6),
            )}, url(${training_header_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: 'auto', textAlign: 'center' }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down('md')]: {
                  fontSize: size['3xl'],
                },
              })}
            >
              {type === "CX" ? "Customer Experience Training Services"
              : type === "CS" ? "Customer Service Training Services"
              : type === "TW" ? "Training Documents Preparation Services"
              : "Default Title"}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Unlock the potential of your team! Our customer service training equips your employees with the tools and knowledge to deliver outstanding experiences. Let us discuss your specific needs in a free consultation.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Training trainingData={trainingData.header}/>
        <WhyUs />
        <Training trainingData={trainingData.footer}/>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

TrainingInfo.propTypes = {
    type: PropTypes.string.isRequired,
};

export default TrainingInfo;
