// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';
//import MKTypography from '../../../components/typography';
// Material Kit 2 React examples
import CenteredBlogCard from '../../../examples/Cards/BlogCards/CenteredBlogCard';

// Images
import portfolio_1 from '../../../assets/images/portfolio/portfolio_1.jpeg';
import portfolio_2 from '../../../assets/images/portfolio/portfolio_2.jpeg';
import portfolio_3 from '../../../assets/images/portfolio/portfolio_3.jpeg';
import portfolio_4 from '../../../assets/images/portfolio/portfolio_4.jpeg';
//import routes from '../../../routes';
import MKTypography from '../../../components/typography';

const Industry = () => {
  const menuItemActions = [
    {
      education: {
        route: {
          enhanced: '/pages/portfolio_industry/education/enhanced_learning_use_case',
          learning: '/pages/portfolio_industry/education/learning_center_use_case',
          smart: '/pages/portfolio_industry/education/smart_school_use_case',
        },
        menuItemLabels: {
          enhanced: 'Enhanced Learning Use Case',
          learning: 'Learning Center Use Case',
          smart: 'Smart School Use Case',
        },
      },
      ecommerce: {
        route: {
          retail: '/pages/portfolio_industry/ecommerce/retail_solution_use_case',
          grocery: '/pages/portfolio_industry/ecommerce/online_grocery_use_case',
          beauty: '/pages/portfolio_industry/ecommerce/beauty_supply_use_case',
        },
        menuItemLabels: {
          retail: 'Retail Use Case',
          grocery: 'Online Grocery Use Case',
          beauty: 'Beauty Supply Use Case',
        },
      },
      health: {
        route: {
          appointment: '/pages/portfolio_industry/health_care/appointment_booking_use_case',
          health: '/pages/portfolio_industry/health_care/health_monitoring_use_case',
          connecting:
            '/pages/portfolio_industry/health_care/Connecting_patients_and_health_care_providers_use_case',
        },
        menuItemLabels: {
          appointment: 'Appointment Booking Use Case',
          health: 'Health Monitoring Use Case',
          connecting: 'Connecting Patients and Health Care Use Case',
        },
      },
      finance: {
        route: {
          loan: '/pages/portfolio_industry/finance/loan_service_use_case',
          financial: '/pages/portfolio_industry/finance/financial_management_use_case',
          partner: '/pages/portfolio_industry/finance/partner_draw_use_case',
        },
        menuItemLabels: {
          loan: 'Loan Service Use Case',
          financial: 'Financial Management Service Use Case',
          partner: 'Partner Draw Use Case',
        },
      },
    },
  ];

  return (
    <MKBox component="section" py={1}>
      <Grid
        container
        item
        xs={12}
        lg={8}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ mx: 'auto', textAlign: 'center', pt: '1px', pb: '1px' }}
      >
        <MKTypography variant="body1" color="#344667" opacity={0.8} mt={1} mb={1}>
          We are thrilled to present our comprehensive range of services designed to meet your
          specific needs and drive your business forward.
        </MKTypography>
      </Grid>
      <Container>
        <Grid container spacing={3} alignItems="center" mt={10}>
          <Grid item xs={12} lg={3} sx={{ ml: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={portfolio_2}
              title="Education Industry"
              action={{
                type: 'dropdown',
                route: '',
                color: 'info',
                label: 'find out more',
              }}
              menuItems={menuItemActions[0]['education']}
              description=""
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ ml: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={portfolio_1}
              title="Finance Industry"
              action={{
                type: 'dropdown',
                route: '',
                color: 'info',
                label: 'find out more',
              }}
              menuItems={menuItemActions[0]['ecommerce']}
              description=""
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ ml: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={portfolio_3}
              title="Health Care Industry"
              action={{
                type: 'dropdown',
                route: '',
                color: 'info',
                label: 'find out more',
              }}
              menuItems={menuItemActions[0]['health']}
              description=""
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ ml: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={portfolio_4}
              title="Ecommerce Industry"
              action={{
                type: 'dropdown',
                route: '',
                color: 'info',
                label: 'find out more',
              }}
              menuItems={menuItemActions[0]['finance']}
              description=""
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default Industry;
