/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCardTwo from 'examples/Cards/InfoCards/DefaultInfoCardTwo';

function Information() {
  return (
      <MKBox component="section" py={12}>
        <Container>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={12}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCardTwo
                        title="Our Mission"
                        description="At our company, we genuinely prioritize your best interests. In line with this commitment, our mission is to transform the outlook of every company we collaborate with, ensuring a more positive and prosperous future than they have ever experienced before."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCardTwo
                        title="Our Approach"
                        description="We understand that every business situation is unique, and we avoid using a one-size-fits-all approach. Our six-step process guides our engagements, allowing us to tailor our solutions to meet the individual goals and objectives of each client."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  );
}

export default Information;
