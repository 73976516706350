/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

// Material Kit 2 React examples
import DefaultInfoCardTwo from '../../../examples/Cards/InfoCards/DefaultInfoCardTwo';

//Image
import training_1 from '../../../assets/images/training/training_5.jpeg';

//CSS
import './Training.css';
import PropTypes from "prop-types";

function Training({ trainingData }) {
  return (
    <MKBox component="section" pt={12} pb={5}>
      <Container>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 3, lg: 2 } }}>
            <img className="training-img" src={training_1} alt="training image" />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <DefaultInfoCardTwo
                    title={trainingData.title}
                    description={trainingData.description}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Training.propTypes = {
  trainingData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired
};

export default Training;
