/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

import '../../Training/sections/Training.css';

function Benefits() {
  return (
    <MKBox component="section" pt={5}>
      <Container>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="number">4</span>
                    <span>Major Benefits of Managed Services and Staff Augmentation</span>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div>
                    <span className="training-benefit-list">
                      <i className="fas fa-chart-line icon"></i> Cost Efficiency
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      By choosing our services, organizations can eliminate the expenses associated
                      with building and maintaining in-house teams, infrastructure, and resources.
                      Let us handle the complexities while you enjoy the peace of mind that comes
                      with streamlined financial management. Explore the cost-saving advantages of
                      service today.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-bullseye icon"></i> Focus on Core Competencies
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Our services effectively free up internal resources, allowing you to
                      concentrate on activities that directly contribute to your organization&apos;s
                      growth and competitive advantage. With M Company as your trusted partner, you
                      can confidently optimize your operations while we handle the management of
                      essential functions.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center" style={{ marginTop: '30px' }}>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-brain icon"></i> Enjoy Skills and Expertise
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Whether you require developers, designers, project managers, or subject matter
                      experts, we provide access to a vast pool of talented professionals with the
                      desired skill set. By leveraging our network of experts, you can enhance your
                      team with the right talent, precisely when you need it.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-cogs icon"></i> Flexibility and Agility
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Our services enable organizations to quickly adjust their workforce based on
                      project requirements, ensuring optimal resource allocation and timely response
                      to changing business demands. With M Company as your partner, you can
                      effectively manage staffing fluctuations, maintain agility, and drive
                      operational efficiency. Stay ahead of your business demands with our adaptable
                      workforce solutions.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Benefits;
