// Material Kit 2 React components
import MKBox from '../../../components/box';
//CSS
import '../../Training/sections/Training.css';
// @mui icons
import Groups2RoundedIcon from '@mui/icons-material/GroupsRounded';
import TransferWithinAStationRoundedIcon from '@mui/icons-material/TransferWithinAStationRounded';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import CenterFocusStrongRoundedIcon from '@mui/icons-material/CenterFocusStrongRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DrawRoundedIcon from '@mui/icons-material/DrawRounded';
// @mui material components
import { Container, Grid } from '@mui/material';


function WhyUs() {

  return (
    <MKBox component="section" style={{ background: '#f0f2f5' }}>
      <Container>
        <Grid container spacing={2} pt={10} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span>Why Choose Us?</span>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      <Groups2RoundedIcon fontSize="large"  className="icon" /> Satisfaction Research & Voice of the Customer Analysis
                    </span>
                  </div>
                  <div className="training-description">
                    <p>Understanding what your customers love about your business and where there is room for improvement is essential for fostering loyalty and driving growth. At Strategy Sync, we conduct detailed surveys and feedback sessions to gauge customer satisfaction levels. By leveraging advanced tools and techniques, we also analyze customer feedback from multiple channels, providing insights into their expectations, preferences, and pain points. This combined approach ensures a comprehensive understanding of your customer base, enabling you to address their needs effectively and create exceptional experiences.</p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      <TransferWithinAStationRoundedIcon fontSize="large"  className="icon" />Customer Journey Mapping
                    </span>
                  </div>
                  <div className="training-description">
                    <p style={{marginBottom:"30px"}}>
                      The customer journey is a complex web of interactions that can make or break your relationship with them. Our customer journey mapping service at Strategy Sync involves creating detailed maps of your customer&rsquo;s journey from initial contact to post-purchase interactions. By visualizing each touchpoint, we can identify key moments of truth and opportunities for enhancement. This process helps in pinpointing areas where improvements can be made to ensure a seamless and satisfying experience for your customers at every stage of their interaction with your business.</p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center" style={{ marginTop: '30px' }}>
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      <AddchartRoundedIcon fontSize="large" className="icon" />Data Analytics
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Data is the backbone of effective customer experience management. At Strategy Sync, we utilize both qualitative and quantitative data to provide a deep understanding of customer behavior, preferences, and trends. Our data analytics services include:
                    </p>
                  </div>
                  <div className="training-description">
                    <p>
                      <CheckRoundedIcon fontSize="small" className="icon" />
                      <u className="underline">Behavioral Analysis</u>: Tracking and analyzing customer interactions across various touch-points to identify patterns and trends.
                    </p>
                    <p><CheckRoundedIcon fontSize="small" className="icon" /><u className="underline">Segmentation:</u> Dividing customers
                      into meaningful segments based on demographics, behavior, and preferences to tailor marketing
                      and service efforts.</p>
                    <p><CheckRoundedIcon fontSize="small" className="icon" /><u className="underline">Predictive Analytics:</u> Using
                      historical data to predict future customer behaviors and trends, enabling proactive strategies.
                    </p>
                    <p><CheckRoundedIcon fontSize="small" className="icon" /><u className="underline">Sentiment Analysis:</u> Analyzing text
                      data from customer reviews, social media, and feedback to gauge customer sentiments and
                      emotions.</p>
                    <p><CheckRoundedIcon fontSize="small" className="icon" /><u  className="underline">Dashboard & Reporting:</u> Providing real-time dashboards and comprehensive reports to visualize data and track key
                      performance indicators (KPIs).</p>
                    <p style={{ paddingLeft: '30px' }}>This thorough analysis allows for informed decision-making, ensuring that your strategies are data-driven and effective in enhancing the customer experience.</p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      <p><DrawRoundedIcon fontSize="large" className="icon" /></p>Designing Seamless Customer Interactions
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Consistency is key when it comes to delivering a superior customer experience. At Strategy Sync, we specialize in creating cohesive and consistent experiences across all channels, including online, in-store, and mobile. By ensuring that each customer interaction is intuitive, efficient, and pleasant, we help you build a strong brand image and foster positive relationships with your customers. Our design approach focuses on making every touchpoint user-friendly and aligned with customer expectations, resulting in higher satisfaction and loyalty.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      <p><CenterFocusStrongRoundedIcon fontSize="large" className="icon" /></p>Customer-Centric Policies and Processes
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Aligning your business processes and policies with customer needs is crucial for delivering exceptional service. At Strategy Sync, we help you develop and implement customer-centric policies and processes that empower your employees to resolve issues promptly and effectively. By putting the customer at the heart of your business operations, you can create a culture of excellence that not only meets but exceeds customer expectations. This approach not only improves customer satisfaction but also enhances employee engagement and productivity.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyUs;
