/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCardTwo from 'examples/Cards/InfoCards/DefaultInfoCardTwo';

//Image
import enterprise_data_management_5 from '../../../assets/images/enterprise-data-management-page/enterprise_data_management_5.jpeg';

//CSS
import '../../Training/sections/Training.css';

function EnterpriseDataManagement() {
  return (
    <MKBox component="section" pt={12} pb={5}>
      <Container>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 3, lg: 2 } }}>
            <img className="training-img" src={enterprise_data_management_5} alt="server image" />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <DefaultInfoCardTwo
                    title="Enterprise Data Management (EDM)"
                    description="Effective data management practices ensure that data is accurate, trustworthy, and well-governed, enabling organizations to have confidence in the insights derived from analytics.

Analytics, in turn, enhances the value of data by uncovering actionable insights and driving informed decision-making. By leveraging advanced analytics techniques, organizations can gain a deeper understanding of their customers, optimize operations, drive innovation, and achieve their strategic objectives."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default EnterpriseDataManagement;
