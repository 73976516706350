// @mui material components
import Icon from '@mui/material/Icon';

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";
// Pages
import AboutUs from 'layouts/pages/landing-pages/about-us';
import Support from 'layouts/pages/support';
import PortfolioIndustry from 'layouts/pages/portfolioIndustry';
import ContactUs from 'layouts/pages/contact-us';
import Presentation from 'layouts/pages/presentation';
import CXServicesMorePage from 'layouts/pages/cxServicesMorePage';
import EnterpriseDataManagementMorePage from 'layouts/pages/enterpriseDataManagementMorePage';
import ManagedServicesAndStaffAugmentationMorePage from 'layouts/pages/managedServicesAndStaffAugmentationMorePage';
import TrainingMorePage from 'layouts/pages/trainingMorePage';
import SoftwareDevelopmentMorePage from 'layouts/pages/softwareDevelopmentMorePage';
import TermsOfService from './layouts/pages/termsOfService';
import PrivacyPolicy from './layouts/pages/privacyPolicy';
import CSTrainingMorePage from "./layouts/pages/CStrainingMorePage";
import TrainingMaterialMorePage from "./layouts/pages/trainingMaterialMorePage";

const routes = [
  {
    type: 'nav',
    name: 'home',
    icon: <Icon color='info' fontSize='medium'>home</Icon>,
    route: '/pages/presentation',
    component: <Presentation />,
  },
  {
    type: 'nav',
    name: 'services',
    icon: <Icon color='warning' fontSize='medium'>business_center </Icon>,
    collapse: [
      {
        type: 'nav',
        name: 'Customer Experience (CX) Management:',
        description: 'See all Customer Experience (CX) services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Customer Experience (CX) (more)',
            route: '/pages/service/customer-experience-services',
            component: <CXServicesMorePage />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Training',
        description: 'See all Customer Service & CX Training services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Customer Experience Training (more)',
            route: '/pages/service/CX-training',
            component: <TrainingMorePage />,
          },
          {
            type: 'nav',
            name: 'Customer Service Training (more)',
            route: '/pages/service/CS-training',
            component: <CSTrainingMorePage />,
          },
          {
            type: 'nav',
            name: 'Training Manual and Workbook (more)',
            route: '/pages/service/TMW-training',
            component: <TrainingMaterialMorePage />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Business Analysis & Project Management',
        description: 'See all Business Analysis & Project Management services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Business Analysis & Project Management (more)',
            route: '/pages/service/BA-and-PM-services',
            component: <ManagedServicesAndStaffAugmentationMorePage />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Business Policy & Procedure Guide Optimization & Documentation',
        description: 'See all Business Policy & Procedure Guide Optimization & Documentation services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Business Policy & Procedure Guide Optimization & Documentation (more)',
            route: '/pages/service/BP-and-PGO-and-doc',
            component: <EnterpriseDataManagementMorePage />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Software Development',
        description: 'See all software development services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Software Development',
            route: '/pages/service/software-development-more',
            component: <SoftwareDevelopmentMorePage />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Scrum & Agile Development',
        description: 'See all Scrum & Agile Development services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Training',
            route: '/pages/service/scrum-and-agile-services',
            component: <TrainingMorePage />,
          },
        ],
      },
    ],
  },
  {
    type: 'nav',
    name: 'Portfolio',
    icon: <Icon fontSize='medium'>dashboard</Icon>,
    route: '/pages/portfolio',
    component: <PortfolioIndustry />,
  },
  {
    type: 'nav',
    name: 'Support',
    icon: <Icon fontSize='medium'>support</Icon>,
    route: '/pages/support',
    component: <Support />,
  },
  {
    type: 'nav',
    name: 'Contact Us',
    icon: <Icon fontSize='medium'>contacts</Icon>,
    route: '/pages/contact-us',
    component: <ContactUs />,
  },
  {
    type: 'nav',
    name: 'About Us',
    icon: <Icon fontSize='medium'>photo_album </Icon>,
    route: '/pages/landing-pages/about-us',
    component: <AboutUs />,
  }
];

export const buttonRoutes = [
  // {
  //   type: 'but',
  //   name: 'enhanceLearning',
  //   route: '/pages/portfolio_industry/education/enhanced_learning_use_case',
  //   component: <EnhancedLearningCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'learningCentre',
  //   route: '/pages/portfolio_industry/education/learning_center_use_case',
  //   component: <LearningCenterCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'smartSchool',
  //   route: '/pages/portfolio_industry/education/smart_school_use_case',
  //   component: <SmartSchoolCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'retailSolution',
  //   route: '/pages/portfolio_industry/ecommerce/retail_solution_use_case',
  //   component: <RetailSolutionCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'onlineGrocery',
  //   route: '/pages/portfolio_industry/ecommerce/online_grocery_use_case',
  //   component: <OnlineGroceryCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'beautySupply',
  //   route: '/pages/portfolio_industry/ecommerce/beauty_supply_use_case',
  //   component: <BeautySupplyCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'appointmentBooking',
  //   route: '/pages/portfolio_industry/health_care/appointment_booking_use_case',
  //   component: <AppointmentBookingCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'healthMonitoring',
  //   route: '/pages/portfolio_industry/health_care/health_monitoring_use_case',
  //   component: <HealthMonitoringCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'ConnectingPatientsandHealthcareProviders',
  //   route:
  //     '/pages/portfolio_industry/health_care/Connecting_patients_and_health_care_providers_use_case',
  //   component: <ConnectingPatientsandHealthcareProvidersCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'loanService',
  //   route: '/pages/portfolio_industry/finance/loan_service_use_case',
  //   component: <LoanServiceCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'financialManagementService',
  //   route: '/pages/portfolio_industry/finance/financial_management_use_case',
  //   component: <FinancialManagementCaseStudy />,
  // },
  // {
  //   type: 'but',
  //   name: 'partnerDraw',
  //   route: '/pages/portfolio_industry/finance/partner_draw_use_case',
  //   component: <PartnerDrawCaseStudy />,
  // },
  {
    type: 'but',
    name: 'termsOfService',
    route: '/pages/terms_of_service',
    component: <TermsOfService />,
  },
  {
    type: 'but',
    name: 'privacyPolicy',
    route: '/pages/privacy_policy',
    component: <PrivacyPolicy />,
  },
];

export default routes;
