/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKTypography from 'components/typography';
import MKBox from 'components/box';

// Service Band Section
import EnterpriseDataManagementBand from '../sections/EnterpriseDataManagementBand';
import CXManagementBand from '../sections/CXManagementBand';
import ManagedServicesAndStaffAugmentationBand from '../sections/ManagedServicesAndStaffAugmentationBand';
import TrainingBand from '../sections/TrainingBand';
import SoftwareDevelopmentBand from '../sections/SoftwareDevelopmentBand';
import StrategySyncBand from '../sections/StrategySyncBand';
import RotatingCardBack from "../../../../examples/Cards/RotatingCard/RotatingCardBack";
import bgBack from "../../../../assets/images/rotating-card-bg-back.jpeg";
import RotatingCard from "../../../../examples/Cards/RotatingCard";
import RotatingCardFront from "../../../../examples/Cards/RotatingCard/RotatingCardFront";

function ServiceBand() {
  return (
    <>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: 'auto', textAlign: 'center', pt: '100px' }}
        >
          <MKTypography
            variant="h1"
            color="#344667"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down('md')]: {
                fontSize: size['3xl'],
              },
            })}
          >
            Services
          </MKTypography>
          <MKTypography variant="body1" color="#344667" opacity={0.8} mt={1} mb={1}>
            We are thrilled to present our comprehensive range of services designed to meet your
            specific needs and drive your business forward.
          </MKTypography>
        </Grid>
      </Container>
      <MKBox component="section" pt={5}>
        <Container>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} lg={4}>
              <RotatingCard>
                <RotatingCardFront card={<CXManagementBand disType="band" />} />
                <RotatingCardBack
                  image={bgBack}
                  title="Unlock the Power of Customer Experience"
                  description="We help you understand your customers better through satisfaction research, analyzing their voices, and mapping their journeys to identify improvement opportunities. Boost customer loyalty and drive business growth. Contact us for a free consultation to see how we can improve your CX strategy."
                  action={{
                    type: "internal",
                    route: "/pages/service/customer-experience-services",
                    label: "Find Out More",
                  }}
                />
              </RotatingCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RotatingCard>
              <RotatingCardFront card={<EnterpriseDataManagementBand disType="band" />} />
              <RotatingCardBack
              image={bgBack}
              title="Discover More"
              description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
              action={{
                type: "internal",
                route: "/sections/page-sections/page-headers",
                label: "start with header",
              }}
            />
            </RotatingCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RotatingCard>
                <RotatingCardFront card={<SoftwareDevelopmentBand disType="band" />} />
                <RotatingCardBack
                  image={bgBack}
                  title="Discover More"
                  description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
                  action={{
                    type: "internal",
                    route: "/sections/page-sections/page-headers",
                    label: "start with header",
                  }}
                />
              </RotatingCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RotatingCard>
              <RotatingCardFront card={<TrainingBand disType="band" />} />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "start with header",
                }}
              />
            </RotatingCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RotatingCard>
              <RotatingCardFront card={<ManagedServicesAndStaffAugmentationBand disType="band" />} />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "start with header",
                }}
              />
            </RotatingCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <StrategySyncBand disType="band" />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default ServiceBand;
