/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

// Material Kit 2 React examples
import DefaultInfoCardTwo from '../../../examples/Cards/InfoCards/DefaultInfoCardTwo';

// Image
import data_analytics_1 from '../../../assets/images/cx-management/data_analytics_1.jpeg';
import CXMngmt from '../../../assets/images/cx-management/CXMngmt.jpg';

// CSS
import '../../Training/sections/Training.css';
import PropTypes from "prop-types";

function CXManagement({ notes }) {

    return (
        <MKBox component="section" pt={6}>
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={notes === null ? 6 : 5} sx={{ml: '', mt: {xs: 3, lg: 0}}}>
                        <img className="data-analytics-img" src={notes === null ? data_analytics_1 : CXMngmt} alt="data analysis image"/>
                    </Grid>
                    <Grid item xs={12} lg={notes === null ? 6 : 7}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12} md={12}>
                                {notes === null ?
                                  <MKBox mb={6} mt={12}>
                                    <DefaultInfoCardTwo
                                      title="What is Customer Experience Management?"
                                      description="Customer Experience Management (CEM or CXM) is the practice of designing and reacting to customer interactions to meet or exceed their expectations, thereby increasing customer satisfaction, loyalty, and advocacy. It involves a strategic approach to understanding, managing, and improving the end-to-end customer experience across all touchpoints and channels."
                                    />
                                    <DefaultInfoCardTwo
                                      title="Our Process"
                                      description="At Strategy Sync, we help you understand your customers better through satisfaction research, analyzing their voices, and mapping their journeys to identify improvement opportunities. Our comprehensive approach ensures that every interaction your customers have with your business is optimized for their satisfaction and loyalty."
                                    />
                                </MKBox> :
                                  <DefaultInfoCardTwo
                                    title={notes.title}
                                    description={notes.description}
                                    />}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

CXManagement.propTypes = {
    notes: PropTypes.shape({
        title: PropTypes.node.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};

export default CXManagement;
