// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";

// Presentation page components
import ServiceBand from "../LandingPages/Services/serviceBand/ServiceBand";
import TargetTechnologies from "./sections/TargetTechnologies";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/presentation/presentation.gif";
import comingSoon from "assets/images/coming-soon.jpg";

function Presentation() {
  const statements = {
    mission:
      "At StrategySync, we are dedicated to transforming businesses through strategic insights and cutting-edge technology. We provide tailored solutions in customer experience management, business process optimization, policy management, and IT services. Our commitment to excellence and continuous improvement drives us to deliver superior value, foster agile development, and elevate our clients' success in an ever-evolving market",
    vision:
      "To be the leading consultancy that empowers businesses to thrive through exceptional customer experiences, optimized operations, and innovative technology solutions.",
    values:
      "We go beyond just customer experience or technology solutions. Our team’s wealth of knowledge and experience across various disciplines offers a comprehensive approach that optimizes your operations, and empowers your teams, through providing comprehensive solutions tailored to your specific challenges and goals.",
  };

  const displayComingSoon = true;

  return (
    displayComingSoon ? (
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${comingSoon})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      />
    ) : (
      <>
        <DefaultNavbar routes={routes} sticky />
        <MKBox
          minHeight="75vh"
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="info"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                StrategySync
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                Empowering Excellence Through Strategic Collaboration & Innovation!
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Counters type={statements} />
          <ServiceBand displayType="page" />
          <TargetTechnologies />
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </>
    )
  );
}

export default Presentation;
