/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';
import MKTypography from 'components/typography';
import MKButton from 'components/button';

// Images
import about_us_1 from '../../../../assets/images/about-page/about_us_1.jpg';

function JoinUs() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4" style={{ fontSize: '25px' }}>
              Want to join our team?
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={3} style={{ fontSize: '18px' }}>
              We are constantly seeking individuals with a passion for data management, analytics,
              and data science who possess exceptional talent. Get in touch with us to learn more
              about the opportunities available.
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <a href="/pages/contact-us" rel="noopener noreferrer">
                  <MKButton variant="gradient" color="info" sx={{ height: '100%' }}>
                    Contact Us
                  </MKButton>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: 'auto' }}>
            <MKBox position="relative">
              <MKBox component="img" src={about_us_1} alt="macbook" width="80%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default JoinUs;
