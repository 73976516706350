/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

//Image
import training_3 from '../../../assets/images/training/training_3.jpeg';
import training_2 from '../../../assets/images/training/training_2.jpeg';
import training_5 from '../../../assets/images/training/training_5.jpeg';
import training_4 from '../../../assets/images/training/training_4.jpeg';

//CSS
import './Training.css';

function WhyUs() {
  return (
    <MKBox component="section" py={5} style={{ background: '#f0f2f5' }}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
                <MKBox >
                  <div className="training-benefit">
                    <p className="training-benefit">
                      Detailed Offerings:
                    </p>
                  </div>
                  <div className="training-card-container">
                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_3} alt="Image 1" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Interactive Workshops</h3>
                          <p className="training-card-description">
                            Our interactive workshops provide immersive, hands-on training sessions that replicate
                            real-world customer service scenarios. These workshops are meticulously designed to
                            challenge your team with practical problems they might encounter on the job, fostering
                            critical thinking and swift decision-making. Through guided exercises and collaborative
                            activities, your team will enhance their problem-solving abilities and communication skills,
                            ensuring they can handle any customer interaction with confidence and poise.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_5} alt="Image 2" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">
                            Role-Playing Exercises
                          </h3>
                          <p className="training-card-description">
                            Role-playing exercises are a powerful tool in our training arsenal, allowing your team to
                            step into the shoes of both the customer and the service provider. In a safe and controlled
                            environment, they will practice a wide range of scenarios, from handling difficult customers
                            to delivering top-notch service during routine interactions. These exercises help build
                            confidence, sharpen communication techniques, and enable your team to refine their approach
                            to various customer situations, ensuring they are fully prepared to deliver exceptional
                            service in real life.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_4} alt="Image 3" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Customized Training Modules</h3>
                          <p className="training-card-description">
                            Every industry has its unique challenges, and a one-size-fits-all approach to training
                            simply won’t do. Our customized training modules are tailored specifically to your
                            business’s needs, addressing the distinct obstacles your team faces in your sector. We work
                            closely with you to identify your goals and develop content that is not only relevant but
                            also impactful. This personalized approach ensures that your team is equipped with the
                            knowledge and skills necessary to excel in your specific environment, leading to tangible
                            improvements in customer service.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_2} alt="Image 4" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Performance Metrics</h3>
                          <p className="training-card-description">
                            Understanding the impact of training on your business is crucial, which is why we integrate
                            performance metrics into our programs. We provide you with tools and strategies to measure
                            the effectiveness of the training, from customer satisfaction surveys to service delivery
                            analytics. These metrics allow you to track progress, identify areas for further
                            improvement, and demonstrate the return on investment from the training. By analyzing the
                            data, you can make informed decisions that drive continuous enhancement of your customer
                            service operations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </MKBox>
          </Grid>
        </Grid>
        </Container>
    </MKBox>
  );
}

export default WhyUs;
