/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from '../../components/box';
import MKTypography from '../../components/typography';

// Material Kit 2 React examples
import DefaultNavbar from '../../examples/Navbars/DefaultNavbar';
import DefaultFooter from '../../examples/Footers/DefaultFooter';

// Data Analytics page
import CXManagement from './sections/CXManagement';
import WhyUs from './sections/WhyUs';

// Routes
import routes from '../../routes';
import footerRoutes from '../../footer.routes';

// Images
import cx_management from '../../assets/images/cx-management/cx_management.gif';

function CXManagementInfo() {
  const notes = {
    description: (<p>Partnering with Strategy Sync for your Customer Experience Management needs provides a range of
      significant benefits. By thoroughly understanding and addressing customer needs and concerns, you can foster
      stronger relationships and encourage repeat business, thereby increasing customer loyalty. Satisfied customers are
      also more likely to recommend your business to others, enhancing your reputation through positive word-of-mouth
      marketing. Our approach helps in improving customer retention by effectively addressing issues and enhancing the
      overall experience, which in turn can reduce churn rates. Moreover, positive customer experiences are directly
      linked to increased sales, as happy customers are more inclined to make repeat purchases and spend more. A superior
      customer experience can set your business apart from competitors, offering a unique selling proposition that drives
      a competitive advantage. Additionally, our detailed analysis delivers clear, actionable insights that allow you to
      refine and elevate your customer experience strategy for ongoing success.</p>),
    title: "Benefits of Our Services"
  }

  return (
    <>
      <DefaultNavbar
        routes={routes.map((r) => r.type === 'nav' && r)}
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.0),
              rgba(gradients.dark.state, 0.0),
            )}, url(${cx_management})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: 'auto', textAlign: 'center' }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down('md')]: {
                  fontSize: size['3xl'],
                },
              })}
            >
              Customer Experience (CX) Management
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              We help you understand your customers better through satisfaction research, analyzing their voices, and
              mapping their journeys to identify improvement opportunities. Boost customer loyalty and drive business
              growth. Contact us for a free consultation to see how we can improve your CX strategy.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <CXManagement notes={null}/>
        <WhyUs />
        <CXManagement notes={notes}/>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default CXManagementInfo;
